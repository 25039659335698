/* ContactUs.css */

/* Base styles */

#map-container {
    margin-top: 50px;
    margin-bottom: 20px;
}

.contact-form {
   
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #b1b4b8;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif; /* Change the font family */
}

.contact-form h2 {
    margin-bottom: 20px;
    font-family: 'Arial Black', sans-serif; /* Change the font family */
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    font-weight: bold;
    font-family: 'Arial', sans-serif; /* Change the font family */
}

input,
input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif; /* Change the font family */
}

textarea {
    resize: vertical;
}

.conntact-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #204996;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 200px;
    font-family: 'Arial', sans-serif; /* Change the font family */
}

.conntact-button:hover {
    background-color: #0056b3;
}

/* Animations */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0);
    }
}

.contact-form {
    animation: fadeIn 0.5s ease forwards;
}

.form-group,
button[type="submit"] {
    animation: slideIn 0.5s ease forwards;
}

/* Media Queries */

@media screen and (max-width: 768px) {
    .contact-form {
        max-width: 90%;
    }
}

@media screen and (max-width: 576px) {
    .contact-form {
        padding: 15px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    label {
        font-size: 14px;
    }

    input,
    input,
    textarea {
        padding: 8px;
    }

    button[type="submit"] {
        padding: 8px;
    }
}
