/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;
  --font-semi: 600;
  /*===== Colores =====*/
  /*Purple 260 - Red 355 - Blue 224 - Pink 340*/
  /* HSL color mode */
  --hue-color: 224;
  --first-color: hsl(var(--hue-color), 89%, 60%);
  --second-color: hsl(var(--hue-color), 56%, 12%);
  /*===== Fuente y tipografia =====*/
  --body-font: "Poppins", sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: .938rem;
  --smaller-font-size: .75rem;
  /*===== Margenes =====*/
  --mb-2: 1rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /*===== z index =====*/
  --z-back: -10;
  --z-fixed: 100;
}
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1rem;
    --smaller-font-size: .875rem;
  }
}
/* Education Section Styles */
.education {
  padding: 80px 0;
}

.education__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.education__box {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.education__title {
  font-size: 24px;
  margin-bottom: 10px;
}

.education__school {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.education__date {
  font-size: 14px;
  color: #999;
  margin-bottom: 20px;
}

.education__description {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/*===== BASE =====*/
*, ::before, ::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--second-color);
}


h1, h2, p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/*===== CLASS CSS ===== */

.section-title::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  background-color: #0e1730;
}.section-title {
  position: relative;
  font-size: var(--h2-font-size);
  color: #001f3f;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-4);
  text-align: center;
}

/*===== LAYOUT =====*/
.bd-grid {
  max-width: 1024px;
  display: grid;
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}


/*===== NAV =====*/


/*=== Show menu ===*/
.show {
  right: 0;
}

/*===== HOME =====*/
.home {
  position: relative;
  row-gap: 5rem;
  padding: 4rem 0 5rem;
}
.home__data {
  align-self: center;
}
.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-5);
}
.home__title-color {
  color: var(--first-color);
}
.home__social {
  display: flex;
  flex-direction: column;
}
.home__social-icon {
  width: max-content;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: var(--second-color);
}
.home__social-icon:hover {
  color: var(--first-color);
}
.home__img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 260px;
}
.home__blob {
  fill: var(--first-color);
}
.home__blob-img {
  width: 360px;
}

/*BUTTONS*/
.aboutbutton {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 0.75rem 2.5rem;
  font-weight: var(--font-semi);
  border-radius: 0.5rem;
  transition: 0.3s;
}
.aboutbutton:hover {
  box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.15);
}

/* ===== ABOUT =====*/
.about__container {
  row-gap: 2rem;
  text-align: center;
}
.about__subtitle {
  margin-bottom: var(--mb-2);
}
.about__text {
  text-align: justify; /* Justify the text */
  margin: 30px;
}
.about__img {
  justify-self: center;
}
.about__img img {
  width: 200px;
  border-radius: 0.5rem;
}

/* ===== SKILLS =====*/

.skills__data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: var(--font-semi);
  padding: 0.5rem 1rem;
  margin-bottom: var(--mb-4);
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px rgba(14, 36, 49, 0.15);
}
.skills__icon {
  font-size: 2rem;
  margin-right: var(--mb-2);
  color: var(--first-color);
}
.skills__names {
  display: flex;
  align-items: center;
}
.skills__bar {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--first-color);
  height: 0.25rem;
  border-radius: 0.5rem;
  z-index: var(--z-back);
}.skills__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service__box {
  width: calc(33.33% - 20px); /* Adjust the width as needed */
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* Adjust the background color as needed */
}.section-title {
  margin-bottom: 20px; /* Adjust the value as needed */
}

.about__img img {
  width: 100%; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  max-width: 900px; /* Limit the maximum width if necessary */
  display: block; /* Ensure the image takes up the entire container */
  margin: 0 auto; /* Center the image horizontally */
}

.service__box:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

.product__item:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

.work__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.work__item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.work__img {
  display: block;
  width: 100%;
  height: auto;
}

.work__info {
  padding: 20px;
}

.work__title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.work__description {
  font-size: 1rem;
  color: #666;
}
.product {
    padding: 80px 0;
  }
  
  .product__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .product__item {
    border: 2px solid #001f3f; /* Dark navy blue border */
    padding: 20px;
  }
  
  .product__img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .product__info {
    margin-top: 20px;
  }
  
  .product__title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .product__description {
    font-size: 1rem;
    color: #555;
  }
  
/* ===== WORK =====*/
.work__container {
  row-gap: 3rem;
}
.work__img {
  box-shadow: 0px 10px 30px rgba(14, 36, 49, 0.15);
  border-radius: 0.5rem;
  overflow: hidden;
}
.work__img img {
  transition: 2s;
}
.work__img img:hover {
  transform: scale(1.1);
}
.contact__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contact__info {
  text-align: center;
  margin: 20px;
}

.contact__icon {
  font-size: 2rem;
  margin-bottom: 10px;
  border: 2px solid rgb(16, 52, 66);
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.contact__subtitle {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.contact__text {
  font-size: 1rem;
  color: #333;
}

.contact__text a {
  text-decoration: none;
  color: #007bff;
}

/* ===== CONTACT =====*/
.contact__input {
  width: 100%;
  font-size: var(--normal-font-size);
  font-weight: var(--font-semi);
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1.5px solid var(--second-color);
  outline: none;
  margin-bottom: var(--mb-4);
}
.contact__button {
  display: block;
  border: none;
  outline: none;
  font-size: var(--normal-font-size);
  cursor: pointer;
  margin-left: auto;
}
/* Styles for Service Box */
.service__container {
  display: flex;
  justify-content: space-between; /* Adjust to your preference */
}

.service__box {
  flex: 0 1 45%; /* Adjust width as needed */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}


.service__title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service__text {
  text-align: justify; /* Adjust text alignment */
}


/* ===== FOOTER =====*/
/* .footer {
  background-color: var(--second-color);
  color: #fff;
  text-align: center;
  font-weight: var(--font-semi);
  padding: 2rem 0;
}
.footer__title {
  font-size: 2rem;
  margin-bottom: var(--mb-4);
}
.footer__social {
  margin-bottom: var(--mb-4);
}
.footer__icon {
  font-size: 1.5rem;
  color: #fff;
  margin: 0 var(--mb-2);
}
.footer__copy {
  font-size: var(--smaller-font-size);
} */

/* ===== MEDIA QUERIES=====*/
@media screen and (max-width: 320px) {
  .home {
    row-gap: 2rem;
  }
  .home__img {
    width: 200px;
  }
}
@media screen and (min-width: 576px) {
  .home {
    padding: 4rem 0 2rem;
  }
  .home__social {
    padding-top: 0;
    padding-bottom: 2.5rem;
    flex-direction: row;
    align-self: flex-end;
  }
  .home__social-icon {
    margin-bottom: 0;
    margin-right: var(--mb-4);
  }
  .home__img {
    width: 300px;
    bottom: 25%;
  }
  .about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: initial;
  }
  .skills__container {
    grid-template-columns: 0.7fr;
    justify-content: center;
    column-gap: 1rem;
  }
  .work__container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    padding-top: 2rem;
  }
  .contact__form {
    width: 360px;
    padding-top: 2rem;
  }
  .contact__container {
    justify-items: center;
  }
}
@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }
  .section {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .section-title {
    margin-bottom: var(--mb-6);
  }
  .section-title::after {
    width: 80px;
    top: 3rem;
  }
  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }
  .nav__list {
    display: flex;
    padding-top: 0;
  }
  .nav__item {
    margin-left: var(--mb-6);
    margin-bottom: 0;
  }
  .nav__toggle {
    display: none;
  }
  .nav__link {
    color: var(--second-color);
  }
  .home {
    padding: 8rem 0 2rem;
  }
  .home__img {
    width: 400px;
    bottom: 10%;
  }
  .about__container {
    padding-top: 2rem;
  }
  .about__img img {
    width: 300px;
  }
  .skills__container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    align-items: center;
    text-align: initial;
  }
  .work__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
  .home {
    padding: 10rem 0 2rem;
  }
  .home__img {
    width: 450px;
  }
}

.objective__container {
  text-align: center; /* Center align the title */
}

.objective__title {
  margin-top: 20px;
  margin-bottom: 30px;
}

.objective__cards {
  display: flex;
  
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust spacing between cards */
  
}

.objective__card {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: calc(25% - 20px); /* Adjust width for 3 columns */
  border: 3px solid green;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .objective__cards {
    justify-content: center;
  }
  .objective__card {
    width: calc(100% - 20px); /* Make cards full width on smaller screens */
  }
}
.about__img img {
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  max-width: 900px; /* Limit the maximum width if necessary */
  display: block; /* Ensure the image takes up the entire container */
  margin: 0 auto; /* Center the image horizontally */
}
.about__img{
  border: 1px solid black;
  border-right: 60px;
}
