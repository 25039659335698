/* Base Styling */

/* Layout */
.admin-product-operations {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  margin-top: 4%;
}

.sidebar {
  flex-basis: 240px;
  background: linear-gradient(135deg, #002a65 0%, #001b5e 100%);





  color: #fff;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.sidebar button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 15px;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

/* Separate button colors for delete and update */
.sidebar button.delete-button {
  background-color: #ff6b6b; /* Red color for delete button */
}

.sidebar button.update-button {
  background-color: #667eea; /* Blue color for update button */
}

.sidebar button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.main-content {
  flex-grow: 1;
  padding: 40px;
  background-color: #ffffff;
  overflow-y: auto;
}

/* Add some top margin and adjust right side slot */
.main-content {
  margin-top: 20px;
}

/* Add Product Form & Search Section */
.add-product-form,
.search-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  margin-bottom: 30px;
}

.search-section {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-section input {
  flex-grow: 1;
  border: 2px solid #E4E7EB;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
}

/* Product Cards Grid */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.product-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  height: 100%; /* New addition */
}

.product-card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 30px rgba(0,0,0,0.2);
}

.product-image {
  width: 100%;
  height: 200px; /* Fixed height for all images */
  object-fit: cover; /* This ensures the image covers the area, might crop */
}

/* Ensuring content inside cards is well-aligned */
.product-card h3,
.product-card p {
  padding: 0 15px; /* Padding inside card for text */
}

.product-card p {
  margin-bottom: 10px; /* Space at the bottom */
  color: #667788; /* Slight color adjustment for text */
}

/* New CSS for ensuring footer of card (price/delete) is always at bottom */
.product-card-footer {
  margin-top: auto;
  padding: 15px;
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Separate button colors for delete and update */
.product-card-footer button.delete-button {
  background-color: #ff6b6b; /* Red color for delete button */
}

.product-card-footer button.update-button {
  background-color: #667eea; /* Blue color for update button */
}

.product-card-footer button {
  padding: 5px 10px;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-card-footer button:hover {
  filter: brightness(90%); /* Slight dim effect on hover */
}

/* Modal Styling */


.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #667eea;
}

.modal-content h3, .modal-content p {
  margin-top: 20px;
}

.div{
  height: 85%;
}
.bt1{
  margin-bottom: 10px;
  align-self: center;
  padding: 5px;
  background-color: #ff6b6b;
  border: none;
  border-radius: 5px;
  width:50%;
  
}
.bt2{
  margin-bottom: 10px;
  align-self: center;
  padding: 10px;
  padding: 5px;
  width:50%;
  border: none;
  background-color: #1a2b7d;
  color: #fff;
  border-radius: 5px;
}

.bt1:hover{
  cursor: pointer;
  background-color: #ea8181;
}

.bt2:hover{
  cursor: pointer;
  background-color: #15468c;
}

/* Responsive Design */
@media (max-width: 960px) {
  .admin-product-operations {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    flex-basis: auto;
    padding: 20px 10px;
  }

  .main-content {
    padding: 20px;
  }
}

/* Smooth Scrolling */
.main-content {
  scroll-behavior: smooth;
}



/* new css */


.admin-product-operations {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}

.sidebar {
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  width: 250px;
  
  
}

.sidebar button {
  background-color: #34495ea0;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 20px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.sidebar button:hover {
  background-color: #4cc2d4;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ecf0f1;
}

.search-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-section input {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
}

.categories-grid, .subcategories-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.category-card, .subcategory-card {
  background-color: white;
  border: 1px solid #bdc3c7;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.category-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 5%;
  aspect-ratio: 4 / 3;
}

.card-buttons {
  margin-top: 10px;
}

.card-buttons button {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px 0;
}

.card-buttons button:hover {
  background-color: #2980b9;
}
.cat-desp{
  aspect-ratio: 4/3;
}

/* Modal Styles */
.modal {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal h2{
  color: black;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 500px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.modal-content input, .modal-content select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
}

.modal-content button {
  background-color: #2ecc71;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.modal-content button:hover {
  background-color: #27ae60;
}


/* Responsive Design */
@media (max-width: 768px) {
  .admin-product-operations {
    flex-direction: column;
  }
  .sidebar button{
    margin: 10px 0px;
  }
  
  .sidebar {
    
    width: 100%;
    flex: 0 0 auto;
    text-align: center;
  }
  
  .main-content {
    padding: 10px;
  }
}
