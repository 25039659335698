/* Global Reset/Normalize */
 /* h1, h2, h3, p, ul, li, table, td, th, input, button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.orderbody {
  margin-left: 15%;
  margin-top: 5%;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  width: 70%;
}

h2, h3 {
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th, table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f2f2f2;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.input-container {
  margin-bottom: 15px;
}

.input-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-container input[type="number"], .input-container input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.button-container {
  margin-top: 20px;
  text-align: right;
}

.button-container button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-container button:hover {
  background-color: #0056b3;
}

.link-container {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}

.link-container:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .button-container {
    text-align: center;
  }

  .button-container button {
    width: 100%;
    margin-bottom: 10px;
  }

  .link-container {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  body {
    padding: 10px;
  }

  table th, table td {
    padding: 8px;
  }

  .button-container button {
    font-size: 14px;
  }

  .link-container {
    font-size: 14px;
  }
}
