

.orderbg {

  background: url('../../public/Assets/image2.png') no-repeat center center;
  background-size: cover;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 100%;
  border: 2px solid black; /* Black border */
  margin: auto; /* Centering the box */
  margin-top: 5%;
}

.submit-form {
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
}

.submit-form h4 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Ensures both buttons have the same width */
  max-width: 200px; /* Limiting max-width for larger screens */
}

button.btn-primary {
  background-color: #007bff;
  color: white;
}

button.btn-primary:hover {
  background-color: #0056b3;
}

button.btn-success {
  background-color: #28a745;
  color: white;
}

button.btn-success:hover {
  background-color: #218838;
}

.products {
  margin-top: 20px;
}

.product-item {
  background-color: #e9ecef;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.product-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 5px;
}

.product-item hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .orderbg {
    padding: 20px;
  }

  .submit-form {
    padding: 20px;
  }

  .product-item img {
    width: 80px;
    height: 80px;
  }
}
