.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); /* Updated box-shadow */
  margin-bottom: 50px;
}

.content {
  max-width: var(--max-width);
  background-color: #ffffff;
  margin: auto;
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  margin-right: 10px; /* Adjust the margin as needed */
}

.logo a {
  font-size: 1.5rem;
  font-weight: 550;
  font-family: 'Times New Roman', Times, serif;
  color: var(--primary-color);
  transition: 0.3s;
}

nav .logo a:hover {
  color: var(--primary-color-dark);
}

ul {
  display: flex;
  align-items: center;
  gap: 1rem;
  list-style: none;
  background-color: #ffffff;
  transition: left 0.3s;
}

ul li a {
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--text-dark);
  transition: 0.3s;
}

ul li a:hover {
  border-bottom-color: rgb(74, 171, 212);
  color: rgb(18, 29, 84);
}

ul.active li {
  text-align: center;
}

ul.active li a {
  display: block;
}

.menu-toggle {
  display: none;
}

/* Updated media query for responsiveness */

@media (max-width: 870px) {

  .menu-toggle {
    display: block;
    /* padding: 10px; */
    border: none;
    border-radius: 10px;
    cursor: pointer;
    z-index: 100;
  }

  ul {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: calc(100% + 1rem); /* Adjusted to add some space below the navbar */
    flex-direction: column;
    justify-content: center;
    display: none;
    z-index: 99; /* Ensure it's above the rest of the content */
  }

  .toggle-lines {
    /* padding: 2px; */
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 2px;
    margin: 3px 0;
    background-color: black;
    border-radius: 3px;
  }

  ul.active {
    display: flex;
  }

  ul li a {
    font-size: 1.25rem;
  }
}

ul.nav__links li {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

ul.nav__links li a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem; /* Adjust gap as needed */
}