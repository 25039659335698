.invoice-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #000;
  background-color: #fff;
  width: 60%;
  max-width: 1200px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  margin-top: 5%;
}

/* .invoiceheader{
  border: 1px solid black;
  display: flex;
} */

.invoicelogo {
  width: 100px;
  display: block;
  margin: 0 auto 20px;
}

.invoiceheader {
  text-align: center;
  margin-bottom: 20px;
}

.quotation-info,
.ship-bill-info,
.invoice-details,
.totals,
.invoicefooter,
.bank-details {
  margin-bottom: 20px;
}

.quotation-info,
.ship-bill-info,
.invoice-details,
.bank-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.quotation-info p,
.ship-bill-info p,
.invoice-details p,
.totals p,
.invoicefooter p,
.bank-details p {
  margin: 5px 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #000;
  padding: 5px;
  text-align: left;
}

.invoice-table th {
  background-color: #f2f2f2;
}

.invoice-table tfoot td {
  border-top: 2px solid #000;
}

.totals {
  font-size: 12px;
  color: #333;
}

.totals p {
  margin: 5px 0;
}

.invoicefooter {
  font-size: 12px;
  color: #333;
}

.bank-details {
  font-size: 12px;
  color: #333;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}

.button-container button {
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin: 10px 0;
}

.button-container button:hover {
  background-color: #0056b3;
}

.button-container a {
  text-decoration: none;
  color: #007bff;
  font-size: 16px;
  margin: 10px 0;
}

.button-container a:hover {
  text-decoration: underline;
}

/* Print Styles */
@media print {
  .button-container, .header img {
    display: none;
  }

  .invoice-container {
    border: none;
    box-shadow: none;
    width: 100%;
    max-width: none;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .quotation-info,
  .ship-bill-info,
  .invoice-details,
  .bank-details {
    flex-direction: column;
  }

  .button-container {
    flex-direction: column;
  }

  .button-container button,
  .button-container a {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .invoice-container {
    width: 100%;
    margin-top: 10%;
  }
}

@media (max-width: 480px) {
  .invoice-container {
    padding: 10px;
  }

  .invoice-table th,
  .invoice-table td {
    padding: 3px;
  }

  .button-container button,
  .button-container a {
    font-size: 14px;
  }
}
