/* HardnessConversion.css */

#conversiondurete {
  
  max-width: 350px;
  margin: 0 auto;
  border: 1px solid rgba(128, 127, 127, 0.584);
  padding:  20px 40px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(119, 119, 119, 0.79);
}

.input-group {
  margin-bottom: 5px;
}

.input-group-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 30px;
}

.action-bouton {
  text-align: center;
  margin-top: 20px;
}

.submit,
.reset {
  display: inline-block; /* Adjusted display property */
  padding: 10px 20px;
  width: 100px;
  margin: 0 5px; /* Adjusted margin */
  border: none;
  border-radius: 30px;
  cursor: pointer;
  
}

.submit {
  background-color: #092368;
  color: #fff;
}

.reset {
  background-color: #19c6af;
  color: #fff;
}
.calc_head{
  margin-bottom: 20px;
}

.submit:hover
 {
  background-color: #0b427c;
}

.reset:hover{
  background-color: #178f7f;
  
}

@media screen and (max-width: 600px) {
  #conversiondurete {
    max-width: 250px; /* Further reduced max-width for smaller screens */
    padding: 10px; /* Further reduced padding for smaller screens */
  }
}