.services-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  font-family: Arial, sans-serif;
  color: #333;
  margin-top: 6%;
  margin-left: 10%;
  width: 80%;
}

.services-left {
  flex: 1;
  padding: 20px;
  /* background-color: #fff; */
  /* border-right: 1px solid #ddd; */

  margin-right: 10%;
}

.services-right {
  flex: 1;
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  /* background-color: #333; */
  color: white;
}

.service-card {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #092a5c;
  border-radius: 8px;
}

.service-card h3 {
  margin-top: 0;
  font-size: 1.5em;
}

.service-card p {
  margin: 10px 0;
}

.service-card button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.service-card button:hover {
  background-color: #ddd;
}

/* Header styles */
.services-left h2 {
  font-size: 2em;
  font-weight: bold;
}

.services-left p {
  font-size: 1.1em;
  line-height: 1.6;
}

.services-right::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.services-right {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .services-container {
    flex-direction: row;
  }

  .services-left {
    /* border-right: 1px solid #ddd; */
    border-bottom: none;
  }

  .services-right {
    flex: 1;
  }
}