.gallery-container {
  
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-item {
  border: 1px solid #ccc;
  border-radius: 20px;

  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-item img {
  width: 100%; 
  height: 200px;
  object-fit: cover;
  
  display: block;
}

.gallery-item h2 {
  text-align: center;
  font-size: 16px;
  margin: 8px 0;
}

.gallery-item p {
  text-align: left;
  margin: 0;
  padding: 0 8px 8px;
}

.gallery-item ul {
  list-style-type: none;
  padding: 0 8px 8px;
}

.gallery-item ul li {
  display: inline-block;
  margin-right: 3px;
  background-color: #f0f0f0;
  padding: 3px 6px;
  border-radius: 10px;
  font-size: 10px;
}



