/* Product.css */

.product-page {
  font-family: 'Arial, sans-serif';
  padding: 20px;
  background-color: #f5f5f5;
}

.jumbotron {
  text-align: center;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.search-input {
  width: 50%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.search-icon {
  position: absolute;
  right: 25%;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 20px;
  pointer-events: none; /* Ensures the icon does not interfere with input */
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.category-card,
.subcategory-card,
.product-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.category-card:hover,
.subcategory-card:hover,
.product-card:hover {
  transform: translateY(-5px);
}

.category-img,
.product-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  aspect-ratio: 4 / 3;
}

.product-body {
  margin-top: 15px;
}

.product-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.product-price {
  font-size: 16px;
  color: #28a745;
  font-weight: bold;
}

button {
  background-color: #0b3c81;
  color: #fff;
  border: none;
  border-radius: 5px;
 
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #0056b3;
}
