@import url('http://fonts.googleapis.com/css?family=Open+Sans:400,700');






/* The footer is fixed to the bottom of the page */

footer {
  margin-left: 0px;
  border-top-left-radius: 150px;
  border: 1px solid rgb(98, 206, 31);
   
    bottom: 0;
}

@media (max-height:800px) {
    footer {
        position: static;
        
    }
    header {
        padding-top: 40px;
    }
}

.footer-distributed {
    background-color: #111436;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 50px 50px 60px 50px;
    margin-top: 80px;
}

.footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 30%;
}

.footer-distributed h3 {
    color: #1f8934;
    text-shadow: -1px 1px 0 #faf7f7,
                1px 1px 0 #e1d9d9,
                1px -1px 0 #000000,
                -1px -1px 0 #000000;
    font-size: 36px;
    margin: 0px;
    padding-left: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


.footer-distributed h3 span {
    color: #2366bf;
}

/* Footer links */

.footer-distributed .footer-links {
    color: #e1e1e1;
   
    margin: 20px 0 12px;
}

.footer-distributed .footer-links a {
    display: inline-block;
    padding: 0px 10px;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-company-name {
    color: #8f9296;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #e1e1e1;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #e1e1e1;

    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
  color: #e1e1e1;

    text-decoration: none;
    ;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 30%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #e1e1e1;

    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: #e1e1e1;

    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
   
    border-radius: 2px;
    font-size: 30px;
    color: rgb(203, 135, 52);
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
}

.footer-distributed .footer-icons a:hover {
    color: rgb(219, 176, 84)

}

.footer-links a:hover {
    color: #e7a139;
}


@media (max-width: 880px) {
    .footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }
    .footer-distributed .footer-center i {
        margin-left: 0;
    }
   
    
}       
        

        