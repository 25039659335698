.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
  }
  
  .screen__content {
	display: flex;
	flex-direction: column;
	padding: 40px;
	text-align: center;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.411);
  }
  
  .login_img {
	margin:  auto;
	margin-bottom: 30px; /* Center the image horizontally */
	width: 120px;
  }
  
  .sign-in-heading {
	margin-bottom: 20px;
  }
  
  .login__field {
	margin-bottom: 20px;
  }
  
  .login__submit {
	background-color: #0b1f5b;
	color: #fff;
	padding: 10px 20px;
	border-radius: 20px;
	width: 100%;
	font-size: 0.9rem;
	margin-bottom: 5px;
	margin-top: 5px;
	
  }
  
  .login__icon {
	margin-right: 10px;
  }
  
  .login__input {
	width: 250px;
	padding: 15px;
	border: 1px solid #847e7e;
	border-radius: 25px;
	outline: none;
	margin: 0px;
  }
  
  .login__submit:hover {
	transform: scale(1.1);
	
  }
  
  .error-message {
	margin-top: 10px;
	color: #dc3545;
  }
  
  @media screen and (max-width: 480px) {
	
	.login_img {
		margin:  auto;
		margin-bottom: 30px; /* Center the image horizontally */
		width: 80px;
	  }
  }
  