
.quotation-list-container {
  max-width: 1500px; /* Increased max-width for wider container */
  width: 100%;
  margin: auto;
  padding: 20px;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
}

h2 {
  text-align: center;
  font-weight: bold;
  font-size: x-large;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #000000;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Button styles */
.quotation-list-container .check-btn {
  background-color: #87CEEB; /* Sky blue for check button */
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.quotation-list-container .check-btn:hover {
  background-color: #5F9EA0; /* Darker shade of sky blue on hover */
}

.quotation-list-container .uncheck-btn {
  background-color: #28a745; /* Green for uncheck button */
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.quotation-list-container .uncheck-btn:hover {
  background-color: #218838; /* Darker shade of green on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h2 {
    font-size: large;
  }
}

@media (max-width: 576px) {
  h2 {
    font-size: medium;
  }
}

@media (max-width: 400px) {
  h2 {
    font-size: small;
  }
}

@media (max-width: 320px) {
  h2 {
    font-size: x-small; /* Further reduce font size for very small screens */
  }
  
  .quotation-list-container {
    padding: 10px; /* Adjust padding for smaller screens */
  }
  
  table {
    margin-left: 5px;
    margin-right: 5px;
  }
}
