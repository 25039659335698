.feature-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin: 20px;
    flex: 1;
  }
  
  .icon-container {
    display: inline-block;
    background-color: #ff6600;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .feature-card h3 {
    font-size: 1.25em;
    margin-top: 0;
    color: #333;
  }
  
  .feature-card p {
    font-size: 1em;
    color: #666;
  }
  