.jumbotron-container {
  position: relative;
  height: 60vh; /* Increase the height as desired */
  margin-top: 0%;
}

.dark-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay color */
  color: white;
  text-align: center;
  padding: 20px 0;
}

.jumbotron {
  position: relative;
  height: calc(100% - 60px); /* Adjust height considering the dark-heading height */
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity to your liking */
}

/* Container */
.container {
  z-index: 1; /* Ensure content is above the overlay */
}

.jumbotron h2 {
  font-size: 5rem;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .jumbotron h2 {
    font-size: 4rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 576px) {
  .jumbotron h2 {
    font-size: 3rem; /* Further adjust font size for smaller screens */
  }
}

@media (max-width: 400px) {
  .jumbotron-container {
    height: 30vh; /* Decrease height for screens up to 400px */
  }

  .jumbotron h2 {
    font-size: 2rem; /* Further adjust font size for screens up to 400px */
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Jumbotron */
  .jumbotron {
    padding: 40px 0; /* Adjust padding for smaller screens */
    font-size: large; /* Adjust font size for smaller screens */
  }

  /* Search Form */
  .search-form input[type="text"] {
    width: 100%; /* Set width of input to 100% for smaller screens */
    border-radius: 5px; /* Remove specific border-radius for smaller screens */
    margin-right: 0; /* Remove margin-right for smaller screens */
  }

  .search-form button {
    border-radius: 5px; /* Adjust border-radius for smaller screens */
    margin-left: 0; /* Remove margin-left for smaller screens */
  }
}
