/* Home.css */

.homepage {
    /* Styles for the homepage container (optional) */
  }
  
  .hero-section {
    margin-top: 0px;
    /* Styles for the hero section (optional) */
  }
  
  .slider-container {
    margin-top: 0px;
    align-content: left;
    position: relative;
    height: 600px; /* Adjust base height as needed */
    overflow: hidden; /* Hide overflowing content */
    
  }
  
  .slider img {
    height: 600px;
    width: 100%;
    object-fit: cover;
    
  }
  
  .slider {
    display: flex;
    /* position: relative; For absolute positioning of controls */
  }
  
  .slider-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s ease;
  }

  .slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: 2; /* Ensure the overlay is above the images */
  }

  
  
  .prev-slide {
    transform: translateX(-100%);
  }
  
  .next-slide {
    transform: translateX(100%);
  }
  .current-slide {
    z-index: 1;
    transform: translateX(0);
  }
  
  .slogan-box {
    
    color:#fff;
    position: absolute;
    z-index: 10; 
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
   
    /* background-color: #ffffffb5;  */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); Add box shadow */ 
    padding: 20px;
    border-radius: 5px;
  }
  
  .sloganbtn{
    padding: 10px;
    
    width: 200px;
    margin:10px;
    border-radius: 10px;
    background-color: rgb(141, 19, 38);
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: navy;

  }
  .sloganbtn:hover{
    scale: 1.05;
    background-color: rgb(17, 72, 154);
  }

  
  .slogan {
    z-index: 101;
    margin: 10px;
    text-align: left;
    font-size: 3rem;
    /* Set text color for better contrast */
    color: rgb(255, 255, 255);
    
  }
  .sloganlink{
    font-size: 15px;
    color: #fff;
  }
 .sloganpara p{
  font-size: 2rem;
  
 }
 .sloganpara{
  width: 70%;
  margin: 10px 30px;
 }
  @media screen and (max-width: 768px) {
    .slogan-box {
      padding: 10px;
      max-width: 80%;
      display: flex;
      flex-direction: column;
      text-align: left;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
    }
  
    .slogan {
      font-size: 25px;
      margin: 10px 10px;
    }
  
    .button {
      padding: 5px 10px;
      font-size: 14px;
    }
  }
  .sloganpara{
    font-size: 1rem;
    margin: 10px 10px;
   
   }
  
  
@media screen and (max-width: 480px) {
  .slogan-box {
    margin-top: 10px;
    width: 250px;
    padding: 5px;
    max-width: 90%;
   
    
    left: 50%; /* Center horizontally */
    transform: translateX(-50%) translateY(-50%);
  }
  

  .slogan {
    margin: 0px 10px;
    font-size: 16px;
   /* Center align text */
  }

  .sloganbtn {
    width: 150px;
    display: flex;
    height:35px;
    align-items: center;
    justify-content: center;
    
    padding: 3px 3px;
    margin: 10px; /* Center button horizontally */
  }

  .sloganlink{
    font-size: 11px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin :0px;
    color: #fff;
  }

  .sloganpara {
     /* Center align paragraph */
  }
  .sloganpara{
    font-size: 11px;
    margin: 5px 10px;
   }
   .slogan p{
    font-size: 5px;
   }
}
  
  .slider-prev,
  .slider-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2; /* Ensure the navigation buttons appear above the slider images */
    padding: 10px;
    border: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .slider-prev {
    left: 10px;
  }
  
  .slider-next {
    right: 10px;
  }
  
  .slider-prev:hover,
  .slider-next:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  /* Media Queries for Responsiveness */
  
  @media screen and (max-width: 768px) {
    .slider-container {
        align-content: center;
      height: 400px; /* Adjust height for smaller screens */
    }
  
    .slider img {
      height: 400px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .slider-container {
        align-content: center;
      height: 300px; /* Adjust height for even smaller screens */
    }
  
    .slider img {
      height: 300px;
    }
  }
  /* Styles for Firebase Config and LoginForm */

/* Improve readability of comments */
.comment {
  color: #888;
  font-style: italic;
}

/* Style the form container */
.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Style form labels */
.form-label {
  margin-bottom: 5px;
}

/* Style form inputs */
.form-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

/* Style form button */
.form-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

/* Style form button on hover */
.form-button:hover {
  background-color: #0056b3;
}


/* Add this to your existing CSS file */

.hardness_calc {
  
  display: flex;
  position: absolute;
  top: 90px; /* Adjust top position */
  right: 50px; /* Adjust right position */
  z-index: 70;
}

@media screen and (max-width: 768px) {
  /* Adjustments for medium-sized screens */
  .hardness_calc {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 20px; /* Adjust margin-top for medium screens */
  }
}
